import React from "react"
import Layout from "../components/layout"
import SEOHeader from "../components/seo-header"
import "../styles/style.scss"

export default function SpaceAI() {
  return (
    <Layout>
      <SEOHeader
        title="Workplace Advisor by GoFloaters"
        description="Helping startups with their workspace decisions"
        pinterest="true"
      ></SEOHeader>
      <div className="container">
        <div className="row">
          <br></br>
          <iframe
            id="HQOUHHBILH"
            loading="eager"
            src="https://embed.pickaxeproject.com/axe?id=Workplace_Advisor_by_GoFloaters_QURPL&mode=embed_gold&host=beta&theme=light&opacity=100&font_header=Real+Head+Pro&size_header=30&font_body=Real+Head+Pro&size_body=16&font_labels=Real+Head+Pro&size_labels=14&font_button=Real+Head+Pro&size_button=16&c_fb=FFFFFF&c_ff=FFFFFF&c_fbd=888888&c_bb=228DD7&c_bt=FFFFFF&c_t=000000&s_ffo=100&s_bbo=100&s_f=minimalist&s_b=filled&s_t=2&s_r=2"
            width="100%"
            height="600px"
            onMouseOver="this.style.boxShadow='0px 6px 6px -3px rgba(0,0,0,0.1)'"
            onMouseOut="this.style.boxShadow='none'"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}
